import * as React from "react";
import { graphql } from "gatsby";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import {
  Landing,
  Quote,
  FeaturedInitiatives,
  Goals,
  UpcomingEvents,
  RecentUpdates,
  Members,
  Join,
  Community,
} from "../components/Landing";

const IndexPage = ({ data }) => {
  return (
    <div>
      <Navbar />
      <Landing />
      <Quote />
      {/* <FeaturedInitiatives
        featuredInitiatives={data.landing.featured_initiatives}
      /> */}
      <Goals />
      <RecentUpdates posts={data.landing.posts} />
      {/* <UpcomingEvents upcomingEvents={data.landing.upcoming_events} /> */}
      <Members members={data.landing.members} />
      <Join />
      <Community />

      <Footer />
    </div>
  );
};

// export const query = graphql`
//   query IndexPageQuery {
//     landing {
//       id
//       members {
//         avatar
//         name
//       }
//       featured_initiatives {
//         description
//         gallery {
//           image
//           name
//         }
//         name
//         slug
//         subtitle
//       }
//       posts {
//         featured_image
//         read_time
//         slug
//         subtitle
//         thumbnail
//         title
//         author {
//           avatar
//           name
//         }
//       }
//       upcoming_events {
//         date
//         description
//         featured_image
//         link
//         slug
//         subtitle
//         thumbnail
//         time
//         title
//       }
//     }
//   }
// `;

export const query = graphql`
  query IndexPageQuery {
    landing {
      id
      members {
        avatar
        name
      }
      posts {
        featured_image
        read_time
        slug
        subtitle
        thumbnail
        title
        author {
          avatar
          name
        }
      }
      upcoming_events {
        date
        description
        featured_image
        link
        slug
        subtitle
        thumbnail
        time
        title
      }
    }
  }
`;

export default IndexPage;
